import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import { navigate } from 'gatsby-link'
import SEO from '../components/atoms/seo'
import OfficialCommonH3Set from '../components/molecules/officialCommonH3Set'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowHeader from '../components/organisms/officialLowHeader'

const Wrapper = styled.main`
  .p-section-inner {
    max-width: 1080px;
    margin: 0 auto;
    padding: 30px 0 0;
    text-align: center;

    img {
      display: block;
      width: 100%;
      height: auto;
      margin-top: -70px;
    }
  }
`

const NotFoundPage: React.FC<PageProps<GatsbyTypes.NotFoundPageQuery>> = ({
  location,
}) => {
  useEffect(() => {
    if (!location?.pathname?.includes('404.html')) {
      navigate('/404.html')
    }
  }, [])
  return (
    <OfficialLayout location={location} className="p-page-faq">
      <SEO title="Not found" />
      <Wrapper>
        <OfficialLowHeader
          label="Not found"
          description="お探しのページが見つかりません"
        />
        <div className="p-section-inner">
          <OfficialCommonH3Set
            description={
              'アクセスしようとしたページが見つかりませんでした。\nご指定のURLが誤っているか無効になっている可能性があります。'
            }
          />
          <img
            src="/images/official/common/img404.png"
            alt="お探しのページが見つかりません"
          />
        </div>
      </Wrapper>
    </OfficialLayout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
